import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "What are the symptoms of heart disease?",
    body: "Symptoms of heart disease may include chest pain, shortness of breath, fatigue, dizziness, nausea, and palpitations.",
  },
  {
    id: 1,
    title: "What causes heart disease?",
    body: "Heart disease can be caused by factors such as high blood pressure, high cholesterol, smoking, diabetes, family history, and poor lifestyle habits.",
  },
  {
    id: 3,
    title: "How is heart disease diagnosed?",
    body: "Heart disease can be diagnosed through tests such as electrocardiogram (ECG), echocardiogram, stress test, blood tests, and cardiac catheterization.",
  },
  {
    id: 4,
    title: "What are the risk factors for heart disease?",
    body: "Risk factors for heart disease include high blood pressure, high cholesterol, smoking, diabetes, obesity, physical inactivity, and family history.",
  },
  {
    id: 5,
    title: "Can heart disease be prevented?",
    body: "Yes, heart disease can be prevented by maintaining a healthy lifestyle, such as eating a healthy diet, exercising regularly, not smoking, managing stress, and controlling medical conditions like high blood pressure and diabetes.",
  },
  {
    id: 6,
    title: "What treatments are available for heart disease?",
    body: "Treatment options for heart disease may include medications, lifestyle changes, procedures such as angioplasty, stenting or bypass surgery, and cardiac rehabilitation.",
  },
  {
    id: 7,
    title: "What is the difference between a heart attack and cardiac arrest?",
    body: "A heart attack occurs when there is a blockage in the blood vessels that supply blood to the heart, while cardiac arrest is a sudden loss of heart function that causes a person to collapse and stop breathing.",
  },
  {
    id: 8,
    title:"How can I lower my risk of developing heart disease?",
    body: "You can lower your risk of developing heart disease by maintaining a healthy weight, eating a healthy diet, exercising regularly, not smoking, controlling your blood pressure and cholesterol levels, and managing stress.",
  },
  {
    id: 9,
    title:"What should I do if I think I'm having a heart attack?",
    body: "If you think you are having a heart attack, call emergency services immediately and chew on an aspirin (if you are not allergic to it) while waiting for medical help.",
  },
  {
    id: 10,
    title: "How does high blood pressure contribute to heart disease?",
    body: "High blood pressure can cause damage to the blood vessels, which can lead to the formation of blockages and increase the risk of heart disease.",
  },
  {
    id: 11,
    title:"What is congestive heart failure?",
    body: "Congestive heart failure occurs when the heart cannot pump enough blood to meet the body's needs, leading to fluid buildup in the lungs and other tissues.",
  },
  {
    id: 12,
    title:"How does diet affect heart disease?",
    body: "A diet high in saturated and trans fats, salt, and added sugars can increase the risk of heart disease, while a diet rich in fruits, vegetables, whole grains, and lean proteins can lower the risk.",
  },
  {
    id: 13,
    title:"How does exercise help prevent heart disease?",
    body: "Exercise helps lower blood pressure, improve cholesterol levels, reduce inflammation, and help maintain a healthy weight, all of which can lower the risk of heart disease.",
  },
  {
    id: 14,
    title:"What is a cardiac catheterization?",
    body: "A cardiac catheterization is a procedure that involves inserting a thin tube into the blood vessels of the heart to diagnose or treat heart problems.",
  },
  {
    id: 15,
    title:"What is a stent?",
    body: "A stent is a small, metal mesh tube that is placed inside a narrowed or blocked blood vessel to keep it open and improve blood flow.",
  },
  {
    id: 16,
    title:"What is bypass surgery?",
    body: "Bypass surgery is a surgical procedure in which a blood vessel is taken from another part of the body and used to create a new route for blood to flow around a blocked or narrowed artery in the heart.",
  },
  {
    id: 17,
    title:"What is a pacemaker?",
    body: "A pacemaker is a small device that is implanted in the chest to regulate the heartbeat by sending electrical signals to the heart.",
  },
  {
    id: 18,
    title:"Can heart disease be genetic?",
    body: "Yes",
  },
  
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions our clients ask about Heart Diseases</h1>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
