import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Faq from "../sections/faq/HeartDxFaq"
import Footer from "../components/homeNav/footer/Footer"
import BotNav from "../components/bottomNav"
import PageNav from "../components/homeNav/PageNav"


const HeartDx = () => {
    return (
        <>
            <Seo
                title="Heart Disease FAQs | Afyabook"
                description="Frequently asked Questions on Heart Disease"
                img="https://res.cloudinary.com/tripleaim-software/image/upload/v1679675006/blog/heart-attack_fiwdfo.jpg"
                keywords="Heart Disease, Heart Attack, Symptoms of Heart Disease, Heart Disease treatment,Stroke,Heart Disease prevention,Heart Disease medications"
                siteUrl="https://www.afyabook.com/heartDxFaq"
                canonical="https://www.afyabook.com/heartDxFaq"
            ></Seo>
            <PageNav />
            <HeaderContainer>
                <HeroPortfolio>
                    <HeroHeader>Heart Disease FAQs</HeroHeader>
                </HeroPortfolio>
            </HeaderContainer>
            <script type="application/ld+json">
                      {`{
                        "@context": "https://schema.org/",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                              "@type": "Question",
                              "name": "What are the symptoms of heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Symptoms of heart disease may include chest pain, shortness of breath, fatigue, dizziness, nausea, and palpitations."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What causes heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Heart disease can be caused by factors such as high blood pressure, high cholesterol, smoking, diabetes, family history, and poor lifestyle habits."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How is heart disease diagnosed?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Heart disease can be diagnosed through tests such as electrocardiogram (ECG), echocardiogram, stress test, blood tests, and cardiac catheterization."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the risk factors for heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Risk factors for heart disease include high blood pressure, high cholesterol, smoking, diabetes, obesity, physical inactivity, and family history."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can heart disease be prevented?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, heart disease can be prevented by maintaining a healthy lifestyle, such as eating a healthy diet, exercising regularly, not smoking, managing stress, and controlling medical conditions like high blood pressure and diabetes."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What treatments are available for heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Treatment options for heart disease may include medications, lifestyle changes, procedures such as angioplasty, stenting or bypass surgery, and cardiac rehabilitation."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is the difference between a heart attack and cardiac arrest?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A heart attack occurs when there is a blockage in the blood vessels that supply blood to the heart, while cardiac arrest is a sudden loss of heart function that causes a person to collapse and stop breathing."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I lower my risk of developing heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You can lower your risk of developing heart disease by maintaining a healthy weight, eating a healthy diet, exercising regularly, not smoking, controlling your blood pressure and cholesterol levels, and managing stress."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What should I do if I think I'm having a heart attack?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "If you think you are having a heart attack, call emergency services immediately and chew on an aspirin (if you are not allergic to it) while waiting for medical assistance."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How does high blood pressure contribute to heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "High blood pressure can cause damage to the blood vessels, which can lead to the formation of blockages and increase the risk of heart disease."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is congestive heart failure?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Congestive heart failure occurs when the heart cannot pump enough blood to meet the body's needs, leading to fluid buildup in the lungs and other tissues."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How does diet affect heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A diet high in saturated and trans fats, salt, and added sugars can increase the risk of heart disease, while a diet rich in fruits, vegetables, whole grains, and lean proteins can lower the risk."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How does exercise help prevent heart disease?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Exercise helps lower blood pressure, improve cholesterol levels, reduce inflammation, and help maintain a healthy weight, all of which can lower the risk of heart disease."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is a cardiac catheterization?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A cardiac catheterization is a procedure that involves inserting a thin tube into the blood vessels of the heart to diagnose or treat heart problems."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is a stent?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A stent is a small, metal mesh tube that is placed inside a narrowed or blocked blood vessel to keep it open and improve blood flow."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is bypass surgery?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Bypass surgery is a surgical procedure in which a blood vessel is taken from another part of the body and used to create a new route for blood to flow around a blocked or narrowed artery in the heart."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is a pacemaker?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A pacemaker is a small device that is implanted in the chest to regulate the heartbeat by sending electrical signals to the heart."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can heart disease be genetic?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes"
                              }
                            }
                          ]
                      }`}
                    </script>
             <Faq />
            <BotNav />
            <Footer />
        </>
    )
}

export default HeartDx


const HeaderContainer = styled.div`
    margin-top: 70px;
    width:100%;
`
const HeroPortfolio = styled.div`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    
`
const HeroHeader = styled.h1`
    font-size: clamp(1.5rem,6vw, 3rem);
`

